import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import { NextPage } from 'next'
import { PageBuilderSection, PageResponse } from '@liftfoils/models'
import { PageBuilder } from '@liftfoils/page-builder'
import React from 'react'
import { styled } from '@liftfoils/styles'
import {
  ProductReviews,
  seoImageLoader,
  CookieDeclaration,
  AccountButton,
  RestrictedAccessWrapper,
} from '@liftfoils/components'
import { ROUTE_PRIVACY_POLICY } from '@liftfoils/configs'
import { useAccount } from '@liftfoils/services/shopify-service'

const Message = styled('div', {
  display: 'grid',
  placeContent: 'center',
  textAlign: 'center',
  minHeight: '95vh',
  gap: '$8',
})
const Title = styled('div', {
  lift_font: 'heading01',
})

export const DefaultPage: NextPage<PageResponse<PageBuilderSection[]>> = ({
  pageBuilder,
  seo,
  shopifyData,
  shopifyProduct,
  disallowRobots,
  b2bOnly,
  title,
}) => {
  const { asPath } = useRouter()
  const fullUrl = `${process.env['BASE_URL']}${asPath}`
  const isPrivacyPage = asPath.endsWith(ROUTE_PRIVACY_POLICY)
  const seoImage = seoImageLoader(seo?.ogImage)
  const { isB2B } = useAccount()

  if (b2bOnly && !isB2B) {
    return (
      <>
        <NextSeo nofollow noindex title={'Access forbidden'} />
        <Message>
          <Title>Access forbidden</Title>
          <AccountButton appearance={'outlineGray300'} size={'medium'} />
        </Message>
      </>
    )
  }

  return (
    <>
      {seo && (
        <NextSeo
          nofollow={disallowRobots}
          noindex={disallowRobots}
          title={seo?.metaTitle ?? title}
          description={seo?.metaDescription}
          canonical={fullUrl}
          openGraph={{
            url: fullUrl,
            type: 'website',
            title: seo.ogTitle,
            description: seo.ogDescription,
            images: [...(seoImage ? [seoImage] : [])],
          }}
          twitter={{
            cardType: 'summary_large_image',
          }}
        />
      )}
      {pageBuilder?.map((section, index) => {
        if (section.b2bOnly === true) {
          return (
            <RestrictedAccessWrapper key={section._key}>
              <PageBuilder
                section={{ ...section, priority: index === 0 }}
                shopifyData={shopifyData}
                shopifyProduct={shopifyProduct}
              />
            </RestrictedAccessWrapper>
          )
        } else {
          return (
            <PageBuilder
              section={{ ...section, priority: index === 0 }}
              shopifyData={shopifyData}
              shopifyProduct={shopifyProduct}
              key={section._key}
            />
          )
        }
      })}
      {shopifyProduct && <ProductReviews shopifyProduct={shopifyProduct} />}
      {isPrivacyPage && <CookieDeclaration />}
    </>
  )
}
